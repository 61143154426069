
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import appcaches from './caches/index.js';
import plugins from './plugins/index.js';

import package_json from '../package.json';

(function() {	
	window.Grape.modules.push(package_json);

	window.Grape.dialog.registerDialogs(dialogs);
	window.Grape.pages.registerPages(pages);
	window.Grape.component.registerComponents(components);
	window.Grape.cache.registerCaches(appcaches);

	Grape.registry.addItem(
		'PSServerDashboardPages',
		`${pages[0].title}`,
		{
			title: pages[0].title,
			icon: pages[0].icon,
			page_id: pages[0].page_id || pages[0].route || null,
			idx: pages[0].idx
		}
	);
		

	Grape.registry.addItem('DashboardPanelType', 'ChartDisplay',
	{
		name: 'ChartDisplay',
		component: 'chart-display',
		title: 'Chart',
		ui_fields: [
			{name: 'chart_name', type: 'list', title: 'Chart name', items_from_cache: 'ChartsList', list_label_field: 'name', list_value_field: 'name' },
			{name: 'chart_height', type: 'integer', title: 'Chart height'},
			{name: 'chart_width', type: 'integer', title: 'Chart width'},
			{name: 'filters', type: 'text', title: 'Filters', multiline: true, rows: 5}
		]
	});

})();

