
/**
 * List of charts as defined in echarts.v_charts
 * @kind cache
 * @class ChartsList
 */
export default {
	name: 'ChartsList',
	options: {
		table: 'v_charts',
		schema: 'echarts',
		fields: ['name', 'chart_id']
	}
};
