import template from './chart-display.html';

const PREDEFINED_SIZES = {
	'small': ['320px', '550px'],
	'medium': ['400px', '690px'],
	'large': ['460px', '800px'],
	'extra-large': ['520px', '920px']
};

/*chart_options*
 * @kind component
 * @class ChartDisplayViewModel
 * @description Component for displaying a chart
 * @requirements  Chart_name or Chart_id [IF MORE THAN ONE CHART ON PAGE DEFINE CHART NUMBER as {chart_number: 1}], filter is also required
 */
class ChartDisplayViewModel
{
	constructor (params, element)
	{
		this.params = params;
		this.element = element;
		this.show_in_dialog = ko_helper.safe_observable(params.show_in_dialog || false);
		this.chart_size = ko_helper.safe_observable(params.chart_size || null);
		this.chart_height = ko_helper.safe_observable(params.chart_height || null);
		this.chart_width = ko_helper.safe_observable(params.chart_width || null);
		this.chart_name = ko_helper.safe_observable(params.chart_name);
		this.filters = ko_helper.safe_observable(params.filter);
		this.title = ko_helper.safe_observable(params.title);
		this.chart = ko.observable();
		this.chart_options_loading = ko.observable(true);
		this.chart_options_not_loaded = ko.observable(false);
		this.chart_has_data = ko.observable(false);

		if (this.chart_size())
		{
			const presize = PREDEFINED_SIZES[this.chart_size()];
			if (presize)
			{
				if (!this.chart_height())
					this.chart_height(presize[0]);
				if (!this.chart_width())
					this.chart_width(presize[1]);
			}
		}

		this.init();

		this.filters.subscribe(() => {
			if (typeof this.chart_name() != 'undefined')
				this.load_chart()
		});

		this.chart_name.subscribe(() => {
			if (typeof this.filters() != 'undefined')
				this.load_chart();
		});
	}

	async init ()
	{	
		if (typeof this.chart_name() != 'undefined')
			await this.load_chart();
	}

	//TODO change this to something cleaner
	async get_var_value (variable)
	{
		// Create a temp element to apply the CSS var and get its computed value
		let temp_el = document.createElement('div');
		temp_el.style.cssText = `color: ${variable}; position: absolute; visibility: hidden;`;
		document.body.appendChild(temp_el);

		let computed_color = getComputedStyle(temp_el).color;
		document.body.removeChild(temp_el);

		return computed_color;
	}

	async load_chart ()
	{
		this.chart_options_loading(true);
		this.chart_has_data(false);

		try {
			let chartContainer = this.element.getElementsByClassName('ps-echarts-chart-element')[0];

			let my_chart = echarts.init(chartContainer, null, {height: this.chart_height(), width: this.chart_width()});
			let chart_options = await Grape.fetches.getJSON('/api/echarts/chart/options', { chart_name: this.chart_name(), data_source_options: this.filters() });
			if (chart_options.legend === undefined)
			{
				console.error('Chart options is undefined! Code: ', chart_options.code, ' Message: ', chart_options.message);
				this.chart_options_loading(false);
				this.chart_options_not_loaded(true);
				return;
			}
			if (chart_options.legend.textStyleId)
			{
				let text_style_response = await Grape.fetches.getJSON('/api/echarts/chart/text/style', { text_style_id: chart_options.legend.textStyleId });

				let var_color = text_style_response.color || '#fff';
				let text_color = var_color.startsWith('var') ? await this.get_var_value(var_color) : var_color;

				chart_options.legend.textStyle = {
					color: text_color
				};

				chart_options.xAxis.forEach(axis => {
					axis.axisLabel = {rotate: 60, color: text_color, interval: 0, formatter: function (value) { return value.replace(/^(.*)\s(.*)$/, '$1\n$2'); }};
					axis.nameTextStyle = { color: text_color};
				});

				chart_options.yAxis.forEach(axis => {
					axis.axisLabel = {
						color: text_color,
						formatter: (value) => {
							if (axis.name === "Value")
								return 'R ' + value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
							return value;
						}
					};
					axis.nameTextStyle = { color: 'transparent'};
				});

				chart_options.grid = {
					containLabel: true,
				};

				chart_options.series.forEach(x => {
					if(x.type == 'pie')//Specific to Pie charts
					{
						x.radius = '65%';
						x.symbol = '';
						x.selectedMode = 'single';
						x.coordinateSystem = '';
						x.colorBy = 'data';
						//x.label = {show: true, position: 'inside'};

						chart_options.tooltip.trigger = 'item';
						chart_options.emphasis = {'itemStyle': {'shadowBlur': 10,'shadowOffsetX': 0,'shadowColor': 'rgba(0, 0, 0, 0.5)'}}
					}
					else if (x.name == 'Target' || x.name == 'Actual' || x.name == 'Job Creation' || x.name == 'Incidents')
					{
						x.barWidth = '80';
						x.colorBy = 'data';
						x.label = {show: true,position: 'outside'};
					}
					else
					{
						x.barWidth = '40';
					}
				});
			}

			if (chart_options.legend.formatter)
				chart_options.legend.formatter = new Function('name', chart_options.legend.formatter);

			chart_options.tooltip.formatter = (params) => {
				if (!params.length) params = [params];
				let seriesInfo = params.map((x) => {
					let value = x.data && x.data[1] !== undefined ? (x.seriesType === "pie" ? x.data[1] + '%' : x.data[1]) : "N/A";
					return x.marker + "" + (x.seriesName === '' ? x.name : x.seriesName) + " : " + value + "<br>";
				});
				return seriesInfo.join("");
			};
	
			if (chart_options.dataset)
			{
				chart_options.dataset.forEach((dataset, index) => {
					if (dataset.source && dataset.source.length > 0)
						this.chart_has_data(true);
				});
			}

			if (this.chart_has_data() === true)
				my_chart.setOption(chart_options);
			else
				my_chart.clear();

		} catch (error) {
			console.error(error);
			this.chart_options_not_loaded(true);
		}

		this.chart_options_loading(false);
	}
}

export default {
	name: 'chart-display',
	viewModel: ChartDisplayViewModel,
	module_type: 'ko',
	template: template
};
